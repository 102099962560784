import React from 'react';

import Layout from '../components/Common/Layout/Layout';
import Seo from '../components/Seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="l-container text-center mt-32">
      <h1 className="h-h2">404 - Page Not Found</h1>
    </div>
  </Layout>
);

export default NotFoundPage;
